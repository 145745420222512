<template>
    <TemplateBase v-if="entry">
        <template #hero>
            <HomePageHero
                :hero="hero"
                :images="entry.heroImages"
                :call-to-action="entry.heroCallToAction"
            />
        </template>
        <FlexibleSections :sections="entry.flexibleSections" />
    </TemplateBase>
</template>

<script setup>
import { useHomeQuery } from '~/composables/villus/home/query.ts';
import { useCraftEntrySetup } from '~/composables/useCraftEntrySetup';

const { data } = await useHomeQuery();
const entry = useCraftEntrySetup(data);

const hero = useHeroData(entry);

useColorTheme({
    inverse: true,
    switchOnScroll: true
});
</script>
