<template>
    <BaseHero
        ref="heroElement"
        class="home-page-hero"
    >
        <template #title>{{ hero.title }}</template>

        <div class="home-page-hero__slider-container">
            <div class="home-page-hero__intersection" />

            <BaseSlider
                ref="slider"
                :items="images"
                class="home-page-hero__slider"
            >
                <template #slide="{ item, isVisible }">
                    <div class="home-page-hero__card" :is-visible="isVisible">
                        <JCKIcon class="home-page-hero__icon" />
                        <BaseImage
                            class="base-image--cover home-page-hero__image"
                            :src="item?.w320"
                            :src-tiny="item.w32"
                            :srcset="`
                                ${item?.w320} 320w,
                                ${item?.w768} 768w,
                                ${item?.w980} 980w,
                                ${item?.w1260} 1260w
                            `"
                            sizes="
                                (max-width: 380px) 320px,
                                768px
                            "
                            :height="item.height"
                            :width="item.width"
                            :alt="item.alt"
                            :has-focal-point="item.hasFocalPoint"
                            :focal-point="item.focalPoint"
                        />
                    </div>
                </template>
            </BaseSlider>
        </div>

        <template #description>
            <MetaLine direction="vertical" class="home-page-hero__scroll-intent">
                Scroll
            </MetaLine>
            <div class="home-page-hero__description" v-html="hero.description" /> <!-- eslint-disable-line -->
        </template>

        <template v-if="callToAction" #callToAction>
            <BaseCallToAction
                class="button--inverse"
                :link="callToAction"
            />
        </template>
    </BaseHero>
</template>

<script setup>
import { nextTick } from 'vue';

const props = defineProps({
    hero: {
        type: Object,
        required: true,
        default: () => {}
    },
    images: {
        type: Array,
        required: true,
        default: () => []
    },
    callToAction: {
        type: Object,
        required: false,
        default: () => {}
    },
});

const cards = ref();
const heroElement = ref();
const slider = ref();
const currentSlide = ref(0);

let intersectionObserver, scrollInterval;

const startScrollRight = () => {
    scrollInterval = setInterval(scrollRight, 3000);
};

const scrollRight = () => {
    if (currentSlide.value >= props.images.length - 1) {
        clearInterval(scrollInterval);
    }

    slider.value.scrollRight();
    currentSlide.value++;
};

const onIntersect = (entries) => {
    for (const entry of entries) {
        entry.target.setAttribute('is-visible', entry.isIntersecting);
    }
};

const removeIntersectionObservers = () => {
    intersectionObserver.disconnect();
};

const addIntersectionObservers = () => {
    cards.value = document.querySelectorAll('.home-page-hero .home-page-hero__card');

    if (!intersectionObserver) {
        // The .home-page-hero__intersection determines when a card is intersecting.
        const boundingElementWidth = document.querySelector('.home-page-hero__intersection').getBoundingClientRect().width;
        const margin = (document.body.offsetWidth / 2) - (boundingElementWidth / 2);

        const observerOptions = {
            // N.B: in order to narrow down the intersection area, negative margins are needed
            rootMargin: `0px -${margin}px 0px -${margin}px`,
            threshold: 0.4
        };

        intersectionObserver = new IntersectionObserver(onIntersect, observerOptions);
    }

    cards.value.forEach((el) => {
        intersectionObserver.observe(el);
    });
};

onMounted(async() => {
    if (!process.client) {
        return;
    }

    await nextTick();
    addIntersectionObservers();
    setTimeout(scrollRight, 500);
    startScrollRight();
});

onUnmounted(() => {
    removeIntersectionObservers();
    clearInterval(scrollInterval);
});
</script>

<style lang="less" src="./HomePageHero.less" />
